export default {
    components: {

    },
    data() {
        return {
            title: '',
            item: [],
        }
    },

    methods: {
        forceRerenderView(item) {
            this.item = item
            this.title = 'Error Message'
            this.$refs.modal.show()
        },
        cancel() {
            this.$refs.modal.hide()
        },
    }

}